import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import logoFooter from '../images/sb-logo-wh.svg';
import youtubeIcon from '../images/youtube.svg';
import fbIcon from '../images/facebook-f.svg';
import instaIcon from '../images/instagram.svg';
import twitterIcon from '../images/twitter-svg.svg';
import yelpIcon from '../images/yelp.svg';
import rssIcon from '../images/rss.svg';
import NavigationMenuFooter from '../blocks/NavigationMenuFooter';

const Footer = () => {

	const [moreInfoVisible, setMoreInfoVisible] = useState(false);

	const subdirectory = (window.location.hostname === 'development.solutionbuilt.com'
        ? '/' + window.location.pathname.split('/')[1]
        : ''
    );
    const base_url = (window.location.hostname === 'localhost'
        ? ''
        : `${ window.location.protocol }//${ window.location.hostname }${ subdirectory }`
    );

	return (
		<footer className="flex flex-col mx-auto">
			<div className="inner-footer container mx-auto">
				<div className="flex py-8 top-layout">
					<a href={ `${ base_url }/` }>
						<img src={ logoFooter } alt="Footer Logo" className="footer-logo" width="278px" />
					</a>

					<NavigationMenuFooter />
				</div>
				<div className="bottom-layout-wrapper">
					<div className="flex py-4 bottom-layout container mx-auto">
						<div className="bottom-layout-col">&copy;2025 SolutionBuilt, Inc. All Rights Reserved.</div>
						<div className="bottom-layout-col"><a href={ `${ base_url }/about/join-us` }>JOIN THE SOLUTIONBUILT TEAM!</a></div>
						<div className="bottom-layout-col width-15">
							<ul className="socials">
								<li><a href="https://www.youtube.com/user/solutionbuilt" target="_blank" rel="noopener"><img src={ youtubeIcon } /></a></li>
								<li><a href="https://www.facebook.com/SolutionBuilt/" target="_blank" rel="noopener"><img src={ fbIcon } /></a></li>
								<li><a href="https://www.instagram.com/solutionbuilt/" target="_blank" rel="noopener"><img src={ instaIcon } /></a></li>
								<li><a href="https://twitter.com/SolutionBuilt" target="_blank" rel="noopener"><img src={ twitterIcon } style={{ width: '20px', filter: 'invert(1)'}} /></a></li>
								<li><a href="https://www.yelp.com/biz/solutionbuilt-marietta-3" target="_blank" rel="noopener"><img src={ yelpIcon } /></a></li>
								<li><a href="/blog"><img src={rssIcon} /></a></li>
							</ul>
						</div>
						<div className="bottom-layout-col hidden ">
							<a onClick={ () => setMoreInfoVisible(!moreInfoVisible) } className="more-info">MORE INFO</a>
						</div>
					</div>
				</div>
			</div>

			<div className={`more-info-container ${ (moreInfoVisible) ? 'visible' : '' }` }>
				<div className="container mx-auto">
					<div className="more-info-columns flex">
						<div className="column">
							<h4>Sitemap</h4>
							<ul className="footer-menu">
								<li>
									<Link to= {`${ base_url }/` } >Home</Link>
								</li>
								<li>
									<Link to= {`${ base_url }/mobile-apps` } >Mobile Apps</Link>
								</li>
								<li className="sub-menu">
									<Link to= {`${ base_url }/mobile-apps/android-apps` } >Android Apps</Link>
								</li>
								<li className="sub-menu">
									<a href={ `${ base_url }/mobile-apps/ios-apps` }>iOS Apps</a>
								</li>
								<li className="sub-menu">
									<a href={ `${ base_url }/mobile-apps/cross-platform-apps` }>Cross Platform Apps</a>
								</li>
								<li className="sub-menu">
									<a href={ `${ base_url }/mobile-apps/healthcare-mobile-apps` }>Healthcare Apps</a>
								</li>
								<li className="sub-menu">
									<a href={ `${ base_url }/mobile-apps/enterprise-mobile-apps` }>Enterprise Apps</a>
								</li>
								<li>
									<a href={ `${ base_url }/websites` }>Websites</a>
								</li>
								<li className="sub-menu">
									<a href={ `${ base_url }/websites/e-commerce` }>E-Commerce Websites</a>
								</li>
								<li className="sub-menu">
									<a href={ `${ base_url }/websites/web-portals` }>Web Portals</a>
								</li>

							</ul>
							<ul className="footer-menu">
								<li>Maintenance</li>
								<li className="sub-menu">
									<a href={ `${ base_url }/maintenance-services/website-hosting` }>Website Hosting</a>
								</li>
								<li className="sub-menu">
									<a href={ `${ base_url }/maintenance-services/website-maintenance` }>Website Maintenance</a>
								</li>
								<li className="sub-menu">
									<a href={ `${ base_url }/maintenance-services/mobile-app-maintenance` }>Mobile App Maintenance</a>
								</li>
								<li>
									<a href={ `${ base_url }/about` }>About</a>
								</li>
								<li className="sub-menu">
									<Link to= {`${ base_url }/about/join-us` } >Join Us</Link>
								</li>
								<li className="sub-menu">
									<a href="https://www.solutionbuilt.com/blog/">Blog</a>
								</li>
								<li>
									<a href={ `${ base_url }/contact` }>Contact</a>
								</li>
								<li>
									<a href={ `${ base_url }/get-a-quote` }>Get A Quote</a>
								</li>
							</ul>
						</div>
						<div className="column">
							<h4>About SolutionBuilt</h4>
							<p className="footer-about-copy">
							SolutionBuilt is conveniently located near Atlanta, Georgia in the city of Marietta. We pride ourselves in becoming one of the fastest 
							growing web design and development companies in the Atlanta Metropolitan area focused on servicing any client—simple to sophisticated.
							Over the past 19 years, our team of experts has grown with our business goals, expanding with us as we traverse into the area of cross platform 
							application building and maintaining, custom maintenance integration, and even marketing. 
							Just like the name, our company creates Solutions—Built to last. Our goal is to be your one-stop-shop for all your company’s website, app, maintenance, 
							and integration needs.
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
  );
};

export default Footer;
